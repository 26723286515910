var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"upx-datatable__footer\">\r\n        <div class=\"limit\" data-region=\"limit\"></div>\r\n        <div class=\"page-info\" data-region=\"page-info\"></div>\r\n        <div class=\"pagination\" data-region=\"pagination\"></div>\r\n    </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "    <a class=\"btn-link text-primary h4 upx-datatable__print-btn no-print\" onclick=\"window.print()\">\r\n        <i class=\"fas fa-print\"></i> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":37},"end":{"line":26,"column":65}}}))
    + "\r\n    </a>\r\n    <br>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"upx-datatable-top\">\r\n    <div class=\"settings no-print\" data-region=\"settings\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSettings") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":58},"end":{"line":2,"column":98}}})) != null ? stack1 : "")
    + "></div>\r\n    <div class=\"search\" data-region=\"search\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSearchField") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":88}}})) != null ? stack1 : "")
    + "></div>\r\n    <div class=\"loader-view no-print\" data-region=\"loader\"></div>\r\n</div>\r\n\r\n<div class=\"upx-datatable-main\">\r\n    <div class=\"upx-datatable__presets\" data-region=\"header-presets\" style=\"display: none;\"></div>\r\n    <div class=\"upx-datatable__settings-subheader\" data-region=\"settings-sub-header\"></div>\r\n    <div class=\"upx-datatable__labels-subheader\" data-region=\"labels-sub-header\"></div>\r\n    <div class=\"upx-datatable__table\" data-region=\"table\"></div>\r\n\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPagination") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "")
    + "    <div data-region=\"settings-modal\"></div>\r\n</div>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrintButton") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":29,"column":7}}})) != null ? stack1 : "");
},"useData":true});