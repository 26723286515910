var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>\r\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"firstItem") || (depth0 != null ? lookupProperty(depth0,"firstItem") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstItem","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":17}}}) : helper)))
    + "-"
    + alias4(((helper = (helper = lookupProperty(helpers,"lastItem") || (depth0 != null ? lookupProperty(depth0,"lastItem") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastItem","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":30}}}) : helper)))
    + " "
    + alias4(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"of",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":56}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":71}}}) : helper)))
    + "\r\n</span>";
},"useData":true});